.confirmButton { 
    background-color: var(--colorSystemInfo) !important;
    color: white !important;
    width: 35%;
    text-transform: none;
}

.confirmButton:hover {
    background-color: var(--colorSystemInfo) !important;
}

.cancelButton {
    color: var(--blackAndWhite) !important;
    margin-right: 5px;
    width: 35%;
    text-transform: none;
}

.actionArea{
    margin: 0px 20px 20px;
    text-align: end;
}