.progress{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 5px;
}
.box{
    margin-top: 1.4rem;
    font-size: 1.4rem;
    padding: 1.4rem;
    box-sizing: border-box;
    border-radius: 5px;
}
.risk{
    background: rgba(255, 90, 97, 0.1);
    color: #FF5A61;
    border: 1px solid #FF5A61;
    border-left: 5px solid #FF5A61
}
.weak{
    background: rgba(255, 164, 87, 0.1);
    color: #FFA457;
    border: 1px solid #FFA457;
    border-left: 5px solid #FFA457;
}
.good{
    background: rgba(12, 206, 107, 0.1);
    color: #0CCE6B;
    border: 1px solid #0CCE6B;
    border-left: 5px solid #0CCE6B;
}
.icon{
    width: 60;
    height: 60;
}