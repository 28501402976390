.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
  content: "Small";
  font-size: 0.75em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: "Large";
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
  content: "Huge";
  font-size: 2.5em !important;
}

/* Custom emoji */
.emoji-mart {
  color: var(--colorDarkGrey) !important;
  background: var(--backgroundPaper) !important;
  border: none !important;
  max-width: 100%;
}

.emoji-mart svg {
  fill: var(--iconDefaultcolor) !important;
}

.emoji-mart svg:hover {
  fill: var(--colorDarkGrey) !important;
}

.emoji-mart-anchor-selected svg,
.emoji-mart-anchor-selected svg:hover {
  fill: var(--colorSystemInfo) !important;
}

.emoji-mart-emoji {
  vertical-align: middle !important;
  outline: none !important;
}

.emoji-mart-bar {
  border-color: var(--borderBase) !important;
}

.emoji-mart-anchor-bar {
  background: var(--colorSystemInfo) !important;
}

.emoji-mart .emoji-mart-category-label span {
  background: var(--backgroundPaper);
}

.emoji-mart .emoji-mart-category .emoji-mart-emoji:hover:before {
  background: var(--backgroundDefault);
}

.emoji-mart .emoji-mart-search input {
  color: var(--colorDarkGrey);
  background: var(--backgroundDefault);
  border-color: var(--borderBase);
}

.emoji-mart-search-icon {
  outline: none !important;
}

.emoji-mart .emoji-mart-category-list {
  display: flex;
  flex-wrap: wrap;
}

.emoji-mart-anchor,
.emoji-mart .emoji-mart-category-list .emoji-mart-emoji,
.emoji-mart .emoji-mart-category-list .emoji-mart-emoji span {
  cursor: pointer;
}