
.infoIcon {
    font-size: 14px;
    vertical-align: text-top;
    margin: 2px 5px;
    color: var(--colorDefault);
}

.progress {
    border-radius: 5px;
}

.durationIcon {
    vertical-align: text-bottom;
    margin-right: 5px;
    color: var(--colorDefault);
}

.tooltip {
    display: inline;
}
.scorePercentage {
    text-align: right;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #94959B;
    margin-top: 5px;
}

.duration {
    display: inline;
    color: var(--colorDefault);
}

.root {
    margin : 0;
}
.container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 20px)
}
.imgContainer {
    text-align: center;
}

.imgClass {
    box-sizing: border-box;
    padding: 1em;
    min-width: 285px;
    max-height:172px;
    border-radius: 18px;
    width: auto;
    height: auto;
}
.content {
    margin: 0 6%;
}
.title {
    font-family: 'Roboto', sans-serif;
    color: var(--colorDefault);
    font-style: normal;
    font-weight: 500;
    margin: 0;
    display: inline;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.titleContainer {
    min-height: 30px;
    display: flex;
    align-items: center;
}

.spacing {
    margin-top: 20px;
}

.markCompleteButton {
    color: rgba(12, 206, 107, 1) !important;
    border-color: rgba(12, 206, 107, 1) !important;
}

.quizButton {
    color: var(--colorBase) !important;
    border-color: var(--colorBase) !important;
}
.quizButton:hover{
    background-color: var(--colorBase) !important;
    color: var(--whiteAndBlack) !important;
}

.watchButton {
    background-color: var(--colorBaseBase) !important;
    border-color: var(--colorBaseBase) !important;
    color: var(--buttonLight) !important;
}
.watchButton:hover {
    background-color: var(--buttonLight) !important;
    color: var(--colorBaseBase) !important;
}

.button {
    margin: 0 10px !important;
    width: 40% !important;
    white-space: nowrap !important;
    font-size: 14px !important;
    text-transform: none !important;
}

.fullWidthButton {
    width: 83%;
    white-space: nowrap;
    font-size: 14px;
    text-transform: none;
    margin: 0 10px 20px 10px;
}

.actionArea{
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: auto;
}

.certificateButton {
    margin-bottom: 20px !important;
}

.packageButton {
    width: 93% !important;
}