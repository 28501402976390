.divContainer {
    background-color: var(--backgroundPaper);
    margin: 40px 20px;
    padding: 30px 0 20px 0px;
    border-radius: 8px;
}

.container {
    width: 100% !important;
    margin: 0 !important;
}

.optionalLegend { 
    position: absolute;
    top: 34px;
    background-color: var(--backgroundPaper);
    z-index: 1;
    padding: 0 3px;
    font-size: 85%;
}

.searchField {
    box-sizing: border-box !important;
    height: 40px !important;
    min-width: unset !important;
    max-width: unset !important;
}


.datePicker {
    font-size: 90%;
}

.rootFilter {
    width: 145px !important;
}

@media all and (min-width: 1600px) {
    .rootFilter {
        width: 10vw !important;
    }
}

@media all and (min-width: 0px) and (max-width: 410px) {
    .rootFilter {
        width: 75vw !important;
    }
}
/* 
@media all and (min-width: 1460px) and (max-width: 1600px) {
    .rootFilter {
        width: 150px !important;
    }
} */
/*
@media all and (min-width: 1600px) {
    .rootFilter {
        width: 165px !important;
    }
}
@media all and (min-width: 1800px) {
    .rootFilter {
        width: 200px !important;
    }
}
@media all and (min-width: 1910px) {
    .rootFilter {
        width: 230px !important;
    }
} */

#downloadButton {
    background-color: var(--buttonDefault);
    color: white;
    margin-right: 79px;
    margin-bottom: 50px;
    display: block;
    margin-left: auto;
    text-transform: none;
}

#playButton {
    background-color: var(--buttonInfo);
    border-color: var(--buttonInfo);
    color: white;
    text-transform: none;
}